<template>
	<div class="vehicle-box">
		<div v-for="(item, index) in vehicleList" :key="index" class="vehicle-item" @click="jumpBtn(index)">
			<div class="num">{{ index + 1 }}、</div>
			<div class="vehicle-right">
				<!-- <div v-for="(val, key) in item" :key="key" class="vehicle-detail" v-show="key != 'country' && key != 'hw_id' && key != 'series'">
					<span>{{ key }}:{{ val || "-" }}</span>
				</div> -->
				<div class="vehicle-detail">
					<span>Manufacturers:{{ item.Manufacturers || "-" }}</span>
				</div>
				<div class="vehicle-detail">
					<span>Brand:{{ item.Brand || "-" }}</span>
				</div>
				<div class="vehicle-detail">
					<span>Models:{{ item.Models || "-" }}</span>
				</div>
				<div class="vehicle-detail">
					<span>SOP-EOP:{{ item.SOP || "-" }}-{{ item.EOP || "-" }}</span>
				</div>

				<div class="vehicle-detail">
					<span>ChassisCode:{{ item.ChassisCode || "-" }}</span>
				</div>
				<div class="vehicle-detail">
					<span>Grade:{{ item.Grade || "-" }}</span>
				</div>
				<div class="vehicle-detail">
					<span>TransmissionType:{{ item.TransmissionType || "-" }}</span>
				</div>
				<div class="vehicle-detail">
					<span>DriverPosition:{{ item.DriverPosition || "-" }}</span>
				</div>
				<div class="vehicle-detail">
					<span>VehicleType:{{ item.VehicleType || "-" }}</span>
				</div>
				<div class="vehicle-detail">
					<span>doors:{{ item.Doors || "-" }}</span>
				</div>
				<div class="vehicle-detail">
					<span>EngineModel:{{ item.EngineModel || "-" }}</span>
				</div>
				<div class="vehicle-detail">
					<span>Displacement:{{ item.Displacement || "-" }}</span>
				</div>
				<div class="vehicle-detail">
					<span>PowerKw:{{ item.PowerKw || "-" }}</span>
				</div>
				<div class="vehicle-detail">
					<span>FuelType:{{ item.FuelType || "-" }}</span>
				</div>
				<div class="vehicle-detail">
					<span>DriveModel:{{ item.DriveModel || "-" }}</span>
				</div>
			</div>
			<div class="icon">
				<img src="@/assets/icon/arrow-right-active.png" alt="" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		vehicleList: {
			type: Array,
			default() {
				return [];
			},
		},
	},

	data() {
		return {
			query: {},
		};
	},

	mounted() {
		// console.log(this.vehicleList);
	},

	methods: {
		jumpBtn(index) {
			if (this.vehicleList.length == 1) {
				// console.log("this.vehicleList", this.vehicleList);
				const { chassiscode, hw_id, ChassisCode } = this.vehicleList[0];
				this.query.type = Number(this.$route.query.type);
				if (this.query.type === 1) {
					this.query.chassis_no = chassiscode || ChassisCode;
				} else {
					delete this.query.chassis_no;
				}
				let bread;
				if (this.query.type === 2) {
					const vehicleData = JSON.parse(localStorage.getItem("vehicleData"));

					bread = vehicleData.Brand + "&" + vehicleData.Model;
				}

				this.query.hw_id = hw_id;
				sessionStorage.setItem("vehicleList", JSON.stringify(this.vehicleList[0]));
				this.$router.push({
					path: "/mainGroup",
					query: {
						...this.$route.query,
						...this.query,
						bread,
					},
				});
			} else {
				this.$emit("selectVehicle", index);
			}
		},
	},
};
</script>

<style scoped lang="less">
.icon {
	margin-left: 10px;
	img {
		width: 18px;
		height: 18px;
	}
}
.vehicle-box {
	background: #f4f4f4;
	// background: #f7f7f7;
	border-radius: 4px;
	padding: 30px 20px 13px 30px;
	margin-top: 30px;
	.vehicle-item {
		margin-right: 10px;
		margin-bottom: 17px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
		border-bottom: 1px solid #e6e6e6;
		padding-bottom: 10px;
		cursor: pointer;
		.num {
			width: 40px;
			color: #fd7e14;
		}
		.vehicle-detail {
			width: 25%;
			margin-bottom: 10px;
		}
	}
	.vehicle-right {
		//width: calc(100% - 40px);
		display: flex;
		flex: 1;
		flex-wrap: wrap;
		justify-content: flex-start;
	}
}
</style>
